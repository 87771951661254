import gql from "graphql-tag";

export const googleMapsQuery = gql`
    query googleMaps($latitude: Float!, $longitude: Float!, $radius: Int!, $type: [String!]!) {
      googleMaps(latitude: $latitude, longitude: $longitude, radius: $radius, type: $type) {
        places {
          name
          id
          formattedAddress
          location {
            latitude
            longitude
          }
          websiteUri
          displayName {
            text
            languageCode
          }
          photos {
            name
            widthPx
            heightPx
            authorAttributions {
              displayName
              uri
              photoUri
            }
            flagContentUri
            googleMapsUri
          }
        }
      }
    }`;
