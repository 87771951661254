import client from "./apollo-client";
import { googleMapsQuery } from "../graphql/queries/googleMapsQuery";

async function getGooglePlaces(latitude, longitude, radius, type) 
{
    if (!latitude || !longitude || !radius || !type) {
        throw new Error("Missing required parameters: latitude, longitude, radius, or type");
    }

    try 
    {
        // Query Google Maps API through your GraphQL server
        const data = await client.query({
            query: googleMapsQuery,
            variables: {
                latitude: latitude,
                longitude: longitude,
                radius: radius,
                type: type
            },
            fetchPolicy: 'network-only' // Ensure fresh data from the server
        });

        // Extract results safely without optional chaining
        const places = (data && data.data && data.data.googleMaps && data.data.googleMaps.places) || [];

        return places;
    } 
    catch (e) 
    {
        console.error(`[ERROR]: Failed to fetch Google Places - ${e.message}`);
        return [];
    }
}

export default getGooglePlaces;
