import GroceriesIcon from "../assets/icons/Groceries.svg";
import EntertainmentIcon from "../assets/icons/Entertainment.svg";
import RestaurantsIcon from "../assets/icons/Restaurants.svg";
import KeyBuildingsIcon from "../assets/icons/KeyBuildings.svg";
import PerksIcon from "../assets/icons/Perks.svg";
import TransportationIcon from "../assets/icons/Transportation.svg";
import SchoolsIcon from "../assets/icons/Schools.svg";

export const getIcon = type => {
    switch (type) {
        case "restaurants":
            return RestaurantsIcon;
        case "perks":
            return PerksIcon;
        case "transportation":
            return TransportationIcon;
        case "shopping":
            return GroceriesIcon;
        case "entertainment":
            return EntertainmentIcon;
        case "schools":
            return SchoolsIcon
        default:
            return null;
    }
};

export const mapOptions = {
    styles: [
        {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "poi",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "transit",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        }
    ]
};

export const placesFiller = (type, location = undefined) => {

    if (
        !!location &&
        !!type &&
        !!location[`map_pin_${type}_configuration`] &&
        location[`map_pin_${type}_configuration`].length > 0 &&
        location[`map_pin_${type}_configuration`]
            .filter(it => !!it.google_place_category && typeof it.google_place_category === "string" && it.google_place_category.length > 0).length > 0
    ) {
        const categories = [];
        location[`map_pin_${type}_configuration`].forEach(configuration => {
            if (
                !!configuration.google_place_category &&
                typeof configuration.google_place_category === "string" &&
                configuration.google_place_category.length > 0
            ) {
                categories.push(configuration.google_place_category)
            }
        })

        return categories
    }

    //////////////////////////////////////////////////////
    //PBI 630
    //////////////////////////////////////////////////////

    //Restaurants � ADD
    //�	Restaurant
    //�	Bar

    //Transportation � ADD
    //�	Train Station
    //�	Transit Station
    //�	Subway Station

    //Shopping - ADD
    //�	Bicycle Store
    //�	Book Store
    //�	Department Store
    //�	Hardward Store
    //�	Jewelry store
    //�	Shoe store
    //�	Store

    //Entertainment � ADD
    //�	Amusement Park
    //�	Aquarium
    //�	Casino
    //�	Gym
    //�	Movie Theatre
    //�	Night Club
    //�	Park
    //�	Tourist Attraction

    //////////////////////////////////////////////////////

    switch (type) {
        case "restaurants":
            return [
                "bakery",
                "bar",
                "cafe",
                "restaurant"
            ];
        case "groceries":
            return [
                "convenience_store",
                "supermarket"
            ];
        case "transportation":
            return [
                "airport",
                "light_rail_station",
                "train_station",
                "transit_station",
                "subway_station"
            ];
        case "shopping":
            return [
                "bicycle_store",
                "book_store",
                "clothing_store",
                "department_store",
                "electronics_store",
                "hardware_store",
                "home_goods_store",
                "jewelry_store",
                "pet_store",
                "shoe_store",
                "store",
                "supermarket"
            ];
        case "entertainment":
            return [
                "amusement_park",
                "aquarium",
                "art_gallery",
                "bowling_alley",
                "casino",
                "gym",
                "movie_theater",
                "museum",
                "night_club",
                "park",
                "stadium",
                "spa",
                "tourist_attraction"
            ];
        case "schools":
            return [
                "primary_school",
                "secondary_school",
                "university",
                "school"
            ]
        default:
            return [];
    }
};

export class POIPlace {
    id = "";
    name = "";
    address = "";
    latitude = 0;
    longitude = 0;
    phone = "";
    hour = "";
    website = "";

    constructor({
        id,
        name,
        address,
        latitude,
        longitude,
        phone,
        hour,
        website
    }) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.latitude = latitude;
        this.longitude = longitude;
        this.phone = phone;
        this.hour = hour;
        this.website = website;
    }
}

export class PropertyPlace {
    constructor({ data: { amli_id, longitude, latitude, body } }) {
        this.amli_id = amli_id;
        this.longitude = longitude;
        this.latitude = latitude;
        this.body = body;
    }
}

export const gmapsPlacesFields = [
    'name',
    'formattedAddress',
    'photos',
    'displayName',
    'text',
    'websiteUri',
    'place_id',
    'location',
    'geometry',
    'id',
]

export {
    GroceriesIcon,
    EntertainmentIcon,
    RestaurantsIcon,
    KeyBuildingsIcon,
    PerksIcon,
    SchoolsIcon
};
